<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="promoList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      addText="Create new Promo Code"
      emptyText="No Promo Code found. Please add new Promo Code."
      @download="onExportCSV"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'PromoCodeList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Promo Code List',
      meta: [
        {
          name: 'description',
          content: `Manage Promo Code`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedBulk') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    // if (_.isEmpty(this.$route.query)) {
    //   this.listPromo({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listPromo({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listPromo({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Promo Code List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Promo Id',
          class: { 'text-center': true },
          textKey: 'promoId',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Message',
          class: { 'text-center': true },
          textKey: 'message',
          width: '30%'
        },
        {
          type: 'String',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '15%'
        },
        {
          type: 'String',
          headerText: 'Updated At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '15%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '14%',
          functions: {
            delete: true,
            edit: true
          }
        }
      ],
      searchItems: ['Promo Id']
    };
  },
  computed: {
    ...mapState('promoCode', ['loading', 'baseUrl', 'promoList', 'pagination']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {},
  methods: {
    ...mapActions('promoCode', ['listPromo', 'deleteOne', 'getOne']),
    onAdd() {
      router.push('/promo-code/new');
    },
    onEdit({ row }) {
      router.push(`/promo-code/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>
